<template>
  <div class="create-modal__empty-wrapper">
    <div class="create-modal__empty-icon-wrapper center-align-parent">
      <SvgIconDecorative
        class="center-align-child"
        icon="upload"
        style="height: 30%"
      />
    </div>
    <p>
      <strong>
        <VisibleText>You do not have any datasets uploaded</VisibleText>
      </strong>
      <br />
      <VisibleText>Please </VisibleText>
      <router-link to="/dataset">
        <VisibleText>upload a dataset</VisibleText>
      </router-link>
      <VisibleText>through the dataset wizard.</VisibleText>
    </p>
  </div>
</template>

<script>
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

export default {
  name: "ProjectsCreateModalEmpty",
  components: {
    SvgIconDecorative
  }
}
</script>
